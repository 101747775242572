import Rc from "../assets/Shop/RACECRAFT.webp" 
import Hod from "../assets/Shop/HOD.webp" 
import Md from "../assets/Shop/MD.webp" 
import RcHodCom from "../assets/Shop/COM.webp" 
import RcMdCom from "../assets/Shop/COM23.webp" 
import MdHodCom from "../assets/Shop/COM45.webp" 
import Com from "../assets/Shop/COMCOM.webp" 


export const StickerItems = [
    {
        itemCode: "1",
        itemName: "Racecraft",
        itemImage: Rc,
        itemAbout: "Racecraft sticker is designed to promote racing and to stand against discrimination and inequality. The idea behind this initiative is to bring awareness on modern day problems and to support each other in this car community.",
        cost: "119.00",

    },
    {
        itemCode: "2",
        itemName: "High Octane Diet",
        itemImage: Hod,
        itemAbout: "High Octane Diet is Velocita Racing’s Secret to all those Buff Horsepower our cars put down on the track. This sticker will let everyone know that your vehicle has them Buff Ponies under the hood.",
        cost: "119.00",

    },
    {
        itemCode: "3",
        itemName: "Safe Distance",
        itemImage: Md,
        itemAbout: "Maintain Safe Distance is a creative bumper sticker that promotes physical distancing among people in public places in these Covid-19 times along with safe driving distance between vehicles.",
        cost: "119.00",

    },
    
]

export const comboPacks = [
    {
        itemCode: "4",
        itemName: "Racecraft and Safe Distance Combo Sticker Pack",
        itemImage: RcMdCom,
        itemAbout: "",
        cost: "199.00",

    },
    {
        itemCode: "5",
        itemName: "Racecraft and High Octane Combo Sticker Pack",
        itemImage: RcHodCom,
        itemAbout: "",
        cost: "199.00",

    },
    {
        itemCode: "6",
        itemName: "Safe Distance and High Octane Combo Sticker Pack",
        itemImage: MdHodCom,
        itemAbout: "",
        cost: "199.00",

    },
    {
        itemCode: "7",
        itemName: "Racecraft - Safe Distance - High Octane Combo Sticker Pack",
        itemImage: Com,
        itemAbout: "",
        cost: "289.00",

    },
]