import React from "react";

const EndsTopArrow = () => {
  return (
    <div className="end-max-width" style={{backgroundColor: '#1a1a1a'}}>
      <svg
        className="end-path"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1366 136.187"
        preserveAspectRatio="none"
      >
        <path 
            d="M0,462.211H1366v45.4L962.175,598.4,0,488.857Z" transform="translate(0 -462.211)"
            fill="#2A2A2A"
        >
        </path>
      </svg>
    </div>
  );
};

export default EndsTopArrow;
