export const FooterQuickLinks = [
   
    {
        title: 'Contact us',
        path: '/connect/contact-us/',
    },
    {
        title: 'newsletter',
        path: '/connect/newletter/',
    },
    // {
    //     title: 'sitemap',
    //     path: '/',
    // },
    {
        title: 'Privacy',
        path: '/privacy/',
    },
    {
        title: 'Terms of use',
        path: '/terms-of-use/',
    },
];