export const FooterAbout = [
    {
        title: 'About Us',
        path: '/about/',
    },
    {
        title: 'Faculty',
        path: '/faculty/rajeesh-s-msrit/',
    },
    {
        title: 'Team',
        path: '/our-team/',
    },
    // {
    //     title: 'Formula Bharat',
    //     path: 'https://www.formulabharat.com/',
    // },
];