import Aero from '../assets/Careers/aero.svg'
import Brakes from '../assets/Careers/brakes.svg'
import Chassis from '../assets/Careers/chassis.svg'
import Electrical from '../assets/Careers/electrical.svg'
import Powertrain from '../assets/Careers/powertrain.svg'
import Steering from '../assets/Careers/steering.svg'
import Suspension from '../assets/Careers/suspension.svg'
import BPlan from '../assets/Careers/bplan.svg'
import Sponsorship from '../assets/Careers/sponsorship.svg'
import SocialMedia from '../assets/Careers/social.svg'
import Documenter from '../assets/Careers/documenter.svg'
import Photo from '../assets/Careers/photography.svg'
import Graphic from '../assets/Careers/graphic.webp'
import Writer from '../assets/Careers/writer.webp'




export const TechnicalOptions = [
    {
        title: 'Aerodynamics Engineer',
        about: 'Analyze and design aerodynamic devices to make the downforce required for a quick car. Wings, spoilers, diffusers and more.',
        image: Aero,
    },

    {
        title: 'Brakes Engineer',
        about: 'Designs and manufactures a high performance brake system with low weight and high efficiency.',
        image: Brakes,
    },
    
    {
        title: 'Chassis Design Engineer',
        about: 'The unit that ties all the sub-systems of a car together. The design needs understanding of structures, car balance and mechanical grip.',
        image: Chassis,
    },

    {
        title: 'Electrical Engineer',
        about: 'The nervous system of the car. Design and manufacture the most minimal and robust electrical system according to regulations.',
        image: Electrical
    },

    {
        title: 'Powertrain Engineer',
        about: 'Design and work on a power producing device, the engine. Getting the most power and efficiently putting it to the ground with minimal loss.',
        image: Powertrain
    },

    {
        title: 'Steering Systems Engineer',
        about: 'Design and work on a power producing device, the engine. Getting the most power and efficiently putting it to the ground with minimal loss.',
        image: Steering
    },

    {
        title: 'Suspension Engineer',
        about: 'The department that controls how the car behaves. Most complicated sub system yet the one with highest satisfaction once you hit the optimum grip.',
        image: Suspension
    }
]

export const NonTechnical = [

    {
        title: 'Business Strategist',
        about: 'Make a business strategy for the car and its production to maximize our profits. Static event which requires high understanding of finances and marketing.',
        image: BPlan
    },

    {
        title: 'Sponsorship Team',
        about: 'The team that raises funds for each project, allocates space on the car to sponsoring brands and manages monetary funds.',
        image: Sponsorship
    },

]

export const CreativeRoles = [

    {
        title: 'Social Media Team',
        about: 'Manage our social media pages to show the world what Velocita Racing is. Requires good understanding of social media platforms and how to get a good reach.',
        image: SocialMedia
    },

    {
        title: 'Content Writer',
        about: 'Write copy for website, blog, social media and many other platforms. Work with the team to understand the tone of voice and find the best way to present information',
        image: Writer
    },

    {
        title: 'Photographer / Videographer',
        about: 'Be the eyes of the team. Cover the team' + '\'' + 's journey and create content for documentation and online publicity.',
        image: Photo
    },

    {
        title: 'Graphic Designer',
        about: 'Design our official line of merch, social media posts and more. Work to bring in new design concepts',
        image: Graphic
    },

    {
        title: 'Documenter',
        about: 'Write, manage and compose the documents of all departments, from technical to finances. Requires precise writing and organisational skills.',
        image: Documenter
    },

    {
        title: 'Web Application Developer',
        about: 'Design and develop interractive web applications, Team management applications and many more.',
    },

]