export const FooterCrowd = [
    {
        title: 'Season 2025',
        path: '/crowdfunding/',
    },
    // {
    //     title: 'Payment Status',
    //     path: '/crowdfunding/upi/verify-payment/',
    // },
    {
        title: 'Pay using UPI',
        path: '/crowdfunding/upi/checkout/',
    },
    // {
    //     title: 'Expenses 2022',
    //     path: '/crowdfunding/expenses/2022/',
    // },
];