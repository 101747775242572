import React from "react";

const Ends = () => {
  return (
    <div style={{backgroundColor: '#2a2a2a'}}>
      <svg
        className="end-path"
        xmlns="http://www.w3.org/2000/svg"
        width="1924.625"
        height="192.228"
        viewBox="0 0 1924.625 192.228"
      >
        <path
          id="Path_133"
          data-name="Path 133"
          d="M14504,569.819l146.516,68.653L16428.625,504.6V446.243H14504Z"
          transform="translate(-14504 -446.243)"
          fill="#1A1A1A"
        />
      </svg>
    </div>
  );
};

export default Ends;
