import React from 'react';
import PropTypes from 'prop-types';
import './DialogBox.css';

const DialogBox = ({ title, message }) => {
  return (
    <div className="dialog-box">
      <h2>{title}</h2>
      <p>{message}</p>
    </div>
  );
};

DialogBox.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string
};

export default DialogBox;