export const FooterSponsors = [
    {
        title: 'become a sponsor',
        path: '/partner-with-us/',
    },
    {
        title: 'Sponsors',
        path: '/sponsors/',
    },
    {
        title: 'Query',
        path: '/partner-with-us/contact-team/',
    },
    {
        title: 'Request Brochure',
        path: '/partner-with-us/contact-team/',
    },
];