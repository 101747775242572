export const FooterEvents = [
    {
        title: 'Testing',
        path: '/events/',
    },

    {
        title: 'workshops',
        path: '/events/',
    },

    {
        title: 'recruitment 2023',
        path: '/careers/recruitment/',
    },
];