import React from "react";

const EndsTop = () => {
  return (
    <div style={{backgroundColor: '#2a2a2a'}} className="end-top" >
      <svg
        className='end-path-top'
        xmlns="http://www.w3.org/2000/svg"
        width="1924.625"
        height="192.228"
        viewBox="0 0 1924.625 192.228"
      >
        <path
          id="Path_134"
          data-name="Path 134"
          d="M16428.625,514.9l-146.516-68.653L14504,580.116v58.355h1924.625Z"
          transform="translate(-14504 -446.243)"
          fill='#1a1a1a'
        />
      </svg>
    </div>
  );
};

export default EndsTop;
