import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';

export const FooterSocialItems = [
    {
        title: 'Email',
        path: 'mailto:velocitaracing.rit@gmail.com?',
        icon: EmailIcon,
    },
    {
        title: 'Facebook',
        path: 'https://www.facebook.com/velocitaracing/',
        icon: FacebookIcon,
    },
    {
        title: 'Instagram',
        path: 'https://www.instagram.com/officialvelocitaracing/',
        icon: InstagramIcon,
    },
    {
        title: 'Twitter',
        path: 'https://twitter.com/RacingVelocita',
        icon: TwitterIcon,
    },
    {
        title: 'YouTube',
        path: 'https://www.youtube.com/c/velocitaracing',
        icon: YouTubeIcon,
    },
    {
        title: 'LinkedIn',
        path: 'https://www.linkedin.com/company/velocitaracing',
        icon: LinkedInIcon,
    },
];