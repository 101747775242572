import Satvik from "../../assets/Team/satvik.png";
import Niranjan from "../../assets/Team/boss.jfif";
import Deep from "../../assets/Team/soumyadeep.jpg";
import Rijul from "../../assets/Team/rijul.png";
import Karthik from "../../assets/Team/karthik.jpeg";
import Sanjeev from "../../assets/Team/sanjeev.jpg";
import Abhinav from "../../assets/Team/abhinav.jpg";
import Amar from "../../assets/Team/amar.jpeg";
import Vachan from "../../assets/Team/vachan.jpg";
import Sandesh from "../../assets/Team/sandesh.jpeg";
import Shreyas from "../../assets/Team/shreyas.jpeg";
import Vaibhav from "../../assets/Team/vaibhav.jpg";
import Saikrishna from "../../assets/Team/saikrishna.jpg";
import Keertith from "../../assets/Team/keertith.jpeg";

import Tejas from "../../assets/Team/tejas.jpeg";
import HariAbhijit from "../../assets/Team/hariabhijit.jpg";
import Chiranjeevi from "../../assets/Team/chiranjeevi.jpg";
import Abdul from "../../assets/Team/abdul.jpg";
import Kishore from "../../assets/Team/kishore.jpg";
import Nagarjun from "../../assets/Team/nagarjun.jpg";
import KarthikRakesh from "../../assets/Team/rakesh.jpeg";
import KarthikGowda from "../../assets/Team/gowda.JPG";
import Sumukh from "../../assets/Team/sumukh.jpg";
import Neelay from "../../assets/Team/unde.jpg";
import Sanskar from "../../assets/Team/sanskar.jpg";
import Abhishek from "../../assets/Team/biscuit.jpg";

import Pranjal from "../../assets/Team/pranjal.webp"

import Atharva from "../../assets/Team/atharva.jpeg"
import Anuj from "../../assets/Team/anuj.jpg"

import eric from "../../assets/Team/new/eric.jpg"
import shanmukh from "../../assets/Team/new/shanmukh.jpg"
import chaithanya from "../../assets/Team/new/chaithanya.jpeg"
import arul from "../../assets/Team/new/arul.jpg"
import atindra from "../../assets/Team/new/atindra.jpg"
import changappa from "../../assets/Team/new/changappa.jpg"
import chavi from "../../assets/Team/new/chavi.jpg"
import ganesh from "../../assets/Team/new/ganesh.jpeg"
import neela from "../../assets/Team/new/neela.jpeg"
import pramath from "../../assets/Team/new/pramath.jpeg"
import rishabh from "../../assets/Team/new/rishabh.jpg"
import ryan from "../../assets/Team/new/ryan.jpg"
import sandra from "../../assets/Team/new/sandra.jpg"
import sayhan from "../../assets/Team/new/sayhan.jpg"
import surya from "../../assets/Team/new/surya.jpeg"
import drush from "../../assets/Team/new/drush.jpg"
import monisha from "../../assets/Team/new/monisha.jpg"
import suhas from "../../assets/Team/new/suhas.jpg"
import vastav from "../../assets/Team/new/vasthav.jpg"
import nachi from "../../assets/Team/new/nachi.jpeg"
import kadhi from "../../assets/Team/new/kadhi.jpg"


export const Members = [
  {
    id: "2024",
    teamMembers: [
      
      {
        id: "2024-0",
        name: "Eric Lopez",
        role: "Team Captain",
        image: eric,
        linkedIn: "https://www.linkedin.com/in/eric-lopez-217a0a287",
        emailId: "mailto:lopezeric03@yahoo.com",
    },
    {
        id: "2024-1",
        name: "Shanmukh Giridhar",
        role: "Operations Manager",
        image: shanmukh,
        linkedIn: "https://www.linkedin.com/in/shanmukh-giridhar-817917246",
        emailId: "mailto:shanmukhgiridhar@gmail.com",
    },
    {
      id: "2024-2",
      name: "Chaithanya S",
      role: "Powertrain Lead",
      image: chaithanya,
      linkedIn: "https://www.linkedin.com/in/chaithanya-s-83a27a23",
      emailId: "mailto:Bsadithya2003@gmail.com",
    },
    {
      id: "2023-0",
      name: "Atharva Bilgi",
      role: "Systems Lead",
      image: Atharva,
      linkedIn: "https://in.linkedin.com/in/atharva-bilgi-a61879237",
      emailId: "mailto:atharvapravin28@gmail.com",
    },
    {
      id: "2023-2",
      name: "Hari Abhijit V G",
      role: "Production Lead",
      image: HariAbhijit,
      linkedIn: "",
      emailId: "mailto:hariabhijit2003@gmail.com",
    },
    {
      id: "2024-3",
      name: "Sayhan Shajeer",
      role: "Structures Lead",
      image: sayhan,
      linkedIn: "https://www.linkedin.com/in/sayhan-shajeer-375315248",
      emailId: "mailto:sayhanks@hotmail.com",
    },
    {
      id: "2024-4",
      name: "Ghnanendra Gowda N",
      role: "Electrical Lead",
      image: ganesh,
      linkedIn: "",
      emailId: "mailto:nghnanendragowda@gmail.com",
    },
    {
      id: "2024-5",
      name: "Drush Subbaiah",
      role: "Suspension Lead",
      image: drush,
      linkedin: "https://www.linkedin.com/in/drush-subbaiah-334908243",
      email: "mailto:drushsubbaiah@gmail.com",
    },
    {
      id: "2024-6",
      name: "Suhas Shetty",
      role: "Drivetrain Lead",
      image: suhas,
      linkedin: "https://www.linkedin.com/in/suhas-shetty-059126221",
      email: "mailto:suhassshetty17@gmail.com",
    },
    {
      id: "2024-7",
      name: "Vasthav Alva",
      role: "Steering Lead",
      image: vastav,
      linkedin: "https://www.linkedin.com/in/vasthav-alva-62b06a246",
      email: "mailto:vasthavalva@gmail.com",
    },
    {
      id: "2024-8",
      name: "Nachiketh R",
      role: "Powertrain Engineer",
      image: nachi,
      linkedin: "http://linkedin.com/in/nachikethraj750",
      email: "mailto:nachikethr18@gmail.com",
    },
    {
      id: "2024-9",
      name: "Kadhiravan Nanmaran",
      role: "Brakes Lead",
      image: kadhi,
      linkedin: "https://www.linkedin.com/in/kadhiravan-n-364a20122",
      email: "mailto:nkadhir2002@gmail.com",
    },
    {
      id: "2024-10",
      name: "Suryansh Prajapati",
      role: "Powertrain Engineer",
      image: "https://drk1rd.github.io/host/c21.jpg",
      linkedIn: "https://www.linkedin.com/in/suryanshprajapati/",
      emailId: "mailto:ghdrk@protonmail.com",
    },
    {
      id: "2024-11",
      name: "Atindra Srivastava",
      role: "Brakes Engineer ",
      image: atindra,
      linkedIn: "https://www.linkedin.com/in/atindra-srivastava-2a7a94254",
      emailId: "mailto:atindra2504@gmail.com",
    },
    {
      id: "2024-12",
      name: "Likith Changappa M J",
      role: "Drivetrain Engineer ",
      image: changappa,
      linkedIn: "",
      emailId: "mailto:likithchangappamj@gmail.com ",
    },
    {
      id: "2024-13",
      name: "Rishab M Kamkar",
      role: "Structures Engineer ",
      image: rishabh,
      linkedIn: "https://www.linkedin.com/in/rishab-m-kamkar-4737511a9",
      emailId: "mailto:rishabmkamkar2004@gmail.com",
    },
    {
      id: "2024-14",
      name: "Pramath Umesh Sangurmath",
      role: "Business Plan Lead",
      image: pramath,
      linkedIn: "https://www.linkedin.com/in/pramath-umesh-sangurmath-3a3974259",
      emailId: "mailto:pramathumesh@gmail.com",
    },
    {
      id: "2024-15",
      name: "Chavi Solanki",
      role: "Structures Engineer",
      image: chavi,
      linkedIn: "https://www.linkedin.com/in/chavi-solanki-0549b2290",
      emailId: "mailto:solankichavi234@gmail.com",
    },
    {
      id: "2024-16",
      name: "Ryan Aahil",
      role: "Drivetrain Engineer",
      image: ryan,
      linkedIn: "https://www.linkedin.com/in/ryan-aahil-a24819249",
      emailId: "mailto:ryanaahil@gmail.com",
    },
    {
      id: "2024-17",
      name: "Neelaa B",
      role: "Brakes Engineer",
      image: neela,
      linkedIn: "https://www.linkedin.com/in/neelaa-b-0289382a4/",
      emailId: "mailto:bneelaa@gmail.com",
    },
    {
      id: "2024-18",
      name: "Surya Prakash",
      role: "Electrical Engineer",
      image: surya,
      linkedIn: "",
      emailId: "mailto:suryaprakash083367@gmail.com",
    },
    {
      id: "2024-19",
      name: "Arul ",
      role: "Suspension Engineer",
      image: arul,
      linkedin: "",
      email: "mailto:arulsaini15@gmail.com",
    },
    {
      id: "2024-20",
      name: "Monisha GS",
      role: "Brake Engineer",
      image: monisha,
      linkedin: "https://www.linkedin.com/in/monisha-gs-0a76272a9",
      email: "mailto:monisha2004y@gmail.com",
    },
    

    ],
  },
  {
    id: "2023",
    teamMembers: [
      
      {
        id: "2023-0",
        name: "Atharva Bilgi",
        role: "Team Captain",
        image: Atharva,
        linkedIn: "https://in.linkedin.com/in/atharva-bilgi-a61879237",
        emailId: "mailto:atharvapravin28@gmail.com",
    },
      {
        id: "2023-1",
        name: "Eric Lopez",
        role: "Vice Captain",
        image: eric,
        linkedIn: "https://www.linkedin.com/in/eric-lopez-217a0a287",
        emailId: "mailto:lopezeric03@yahoo.com",
    },
    {
      id: "2023-2",
      name: "Hari Abhijit V G",
      role: "Drivetrain Lead",
      image: HariAbhijit,
      linkedIn: "",
      emailId: "mailto:hariabhijit2003@gmail.com",
    },

    {
        id: "2023-3",
        name: "Shanmukh Giridhar",
        role: "Operations Manager",
        image: shanmukh,
        linkedIn: "https://www.linkedin.com/in/shanmukh-giridhar-817917246",
        emailId: "mailto:shanmukhgiridhar@gmail.com",
    },
    {
      id: "2023-4",
      name: "Chaithanya S",
      role: "Powertrain Lead",
      image: chaithanya,
      linkedIn: "https://www.linkedin.com/in/chaithanya-s-83a27a23",
      emailId: "mailto:Bsadithya2003@gmail.com",
    },
    {
      id: "2023-5",
      name: "Sayhan Shajeer",
      role: "Structures Lead",
      image: sayhan,
      linkedIn: "https://www.linkedin.com/in/sayhan-shajeer-375315248",
      emailId: "mailto:sayhanks@hotmail.com",
    },
    {
      id: "2023-6",
      name: "Ghnanendra Gowda N",
      role: "Electrical Lead",
      image: ganesh,
      linkedIn: "",
      emailId: "mailto:nghnanendragowda@gmail.com",
    },
    {
      id: "2023-7",
      name: "Anuj Deepak",
      role: "Sponsorship Lead",
      image: Anuj,
      linkedIn: "https://in.linkedin.com/in/anuj-deepak-81437b218",
      emailId: "mailto:anujdeepak7@gmail.com",
  },
  {
    id: "2023-8",
    name: "Karthik Rakesh",
    role: "Business Plan Lead",
    image: KarthikRakesh,
    linkedIn: "https://www.linkedin.com/in/karthik-rakesh-b46851222/",
    emailId: "mailto:karthikrakesh15@gmail.com",
  },
    {
      id: "2023-9",
      name: "Chavi Solanki",
      role: "Structures Engineer",
      image: chavi,
      linkedIn: "https://www.linkedin.com/in/chavi-solanki-0549b2290",
      emailId: "mailto:solankichavi234@gmail.com",
    },
    {
      id: "2023-10",
      name: "Drush Subbaiah",
      role: "Suspension Lead",
      image: drush,
      linkedin: "https://www.linkedin.com/in/drush-subbaiah-334908243",
      email: "mailto:drushsubbaiah@gmail.com",
    },
    {
      id: "2023-11",
      name: "Suhas Shetty",
      role: "Drivetrain Engineer",
      image: suhas,
      linkedin: "https://www.linkedin.com/in/suhas-shetty-059126221",
      email: "mailto:suhassshetty17@gmail.com",
    },
    {
      id: "2023-12",
      name: "Vasthav Alva",
      role: "Suspension Engineer",
      image: vastav,
      linkedin: "https://www.linkedin.com/in/vasthav-alva-62b06a246",
      email: "mailto:vasthavalva@gmail.com",
    },
    {
      id: "2023-13",
      name: "Nachiketh R",
      role: "Powertrain Engineer",
      image: nachi,
      linkedin: "http://linkedin.com/in/nachikethraj750",
      email: "mailto:nachikethr18@gmail.com",
    },
    {
      id: "2023-14",
      name: "Kadhiravan Nanmaran",
      role: "Brakes Lead",
      image: kadhi,
      linkedin: "https://www.linkedin.com/in/kadhiravan-n-364a20122",
      email: "mailto:nkadhir2002@gmail.com",
    },
    {
      id: "2023-15",
      name: "Suryansh Prajapati",
      role: "Powertrain Engineer",
      image: "https://drk1rd.github.io/host/c21.jpg",
      linkedIn: "https://www.linkedin.com/in/suryanshprajapati/",
      emailId: "mailto:ghdrk@protonmail.com",
    },
    {
      id: "2023-16",
      name: "Atindra Srivastava",
      role: "Brakes Engineer ",
      image: atindra,
      linkedIn: "https://www.linkedin.com/in/atindra-srivastava-2a7a94254",
      emailId: "mailto:atindra2504@gmail.com",
    },
    {
      id: "2023-17",
      name: "Likith Changappa M J",
      role: "Drivetrain Engineer ",
      image: changappa,
      linkedIn: "",
      emailId: "mailto:likithchangappamj@gmail.com ",
    },
    {
      id: "2023-18",
      name: "Rishab M Kamkar",
      role: "Structures Engineer ",
      image: rishabh,
      linkedIn: "https://www.linkedin.com/in/rishab-m-kamkar-4737511a9",
      emailId: "mailto:rishabmkamkar2004@gmail.com",
    },
    {
      id: "2023-19",
      name: "Ryan Aahil",
      role: "Drivetrain Engineer",
      image: ryan,
      linkedIn: "https://www.linkedin.com/in/ryan-aahil-a24819249",
      emailId: "mailto:ryanaahil@gmail.com",
    },
    {
      id: "2023-20",
      name: "Surya Prakash",
      role: "Electrical Engineer",
      image: surya,
      linkedIn: "",
      emailId: "mailto:suryaprakash083367@gmail.com",
    }

    ],
  },
  {
    id: "2022",
    teamMembers: [
      
      {
        id: "2022-0",
        name: "Satvik S",
        image: Satvik,
        role: "Team Captain",
        intro: '"Desire is the engine of creation."',
        about:
          "I am a creative and motivated problem solver. I learn quickly, love to take intiative and have experience in engineering and design solutions as well as dealing with a realistic and practical approach to issues.",

        linkedIn: "https://www.linkedin.com/in/satvik-s-525364194/",
        emailId: "mailto:satvikcrvk718@gmail.com",
      },

      {
        id: "2022-1",
        name: "Soumyadeep .",
        image: Deep,
        role: "Operations Manager",

        intro: '"Hoot!"',
        about: "",

        linkedIn: "https://in.linkedin.com/in/-deep",
        emailId: "mailto:soumyadeep.br@gmail.com",
      },

      {
        id: "2022-2",
        name: "Shreyas SR",
        image: Shreyas,
        role: "chief financial officer",

        intro:
          '"Learning something new everyday to keep this adventure going on"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/shreyas-sr-281a0820a/",
        emailId: "mailto:shreyassr162001@gmail.com",
      },

      {
        id: "2022-3",
        name: "Sandesh T",
        image: Sandesh,
        role: "chief Technical officer",

        intro: '"Introvert. Machine enthusiast."',
        about: "",

        linkedIn: "https://www.linkedin.com/in/sandesh-t-v-391277143/",
        emailId: "mailto:1ms19me155@gmail.com",
      },

      {
        id: "2022-4",
        name: "Karthik Raghunath",
        image: Karthik,
        role: "Team advisor",

        intro: '"Crank up dreams, mesh with the reality."',
        about: "",

        linkedIn: "https://www.linkedin.com/in/karthik-raghunath",
        emailId: "mailto:karthik.raghunath980@gmail.com",
      },

      {
        id: "2022-5",
        name: "Sai Krishna P Sreenivas",
        image: Saikrishna,
        role: "Head of Training & Development",

        intro: '"In love with Long drives and rides"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/skp313/",
        emailId: "mailto:saikrishna.313@gmail.com",
      },

      {
        id: "2022-6",
        name: "Tejas B N Shetty",
        image: Tejas,
        role: "Event Manager",

        intro: '"Passion and motivation for perfection drives me."',
        about: "",

        linkedIn: "https://www.linkedin.com/in/tejas-bn-shetty-55b397213/",
        emailId: "mailto:tejasbnshetty@gmail.com",
      },

      {
        id: "2022-7",
        name: "Vachan M",
        image: Vachan,
        role: "Lead Vehicle Dynamics Engineer",

        intro: '"Just a guy trying new things | Fascinated by Machines"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/vachan-maruthi-99a098174/",
        emailId: "emailto:agathvegabonds@gmail.com",
      },

      {
        id: "2022-8",
        name: "Amar S Wali",
        image: Amar,
        role: "Lead Brakes Engineer",
        intro: '"All work and no play makes wali a dull boii"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/amar-wali-3210761a0/",
        emailId: "mailto:amarwali0987654321@gmail.com",
      },  

      {
        id: "2022-9",
        name: "Abdul Mateen",
        image: Abdul,
        role: "Structures Lead",

        intro: '""',
        about: "",

        linkedIn: "https://www.linkedin.com/in/abdul-mateen-01/",
        emailId: "mailto:abdulmateen7501@gmail.com",
      },

      {
        id: "2022-10",
        name: "Karthik Rakesh",
        image: KarthikRakesh,
        role: "Lead Powertrain Engineer",

        intro: '"Sometimes you gotta run before you can walk"',
        about: '""',

        linkedIn: "https://www.linkedin.com/in/karthik-rakesh-b46851222/",
        emailId: "mailto:karthikrakesh15@gmail.com",
      },

      {
        id: "2022-11",
        name: "Hari Abhijit V G",
        image: HariAbhijit,
        role: "Lead Drivetrain Engineer",

        intro: '"Love to explore new things and keep learning"',
        about: '""',

        linkedIn: "",
        emailId: "mailto:hariabhijit2003@gmail.com",
      },

      {
        id: "2022-12",
        name: "Karthik Gowda K A",
        image: KarthikGowda,
        role: "Electrical Systems Engineer",

        intro: '"If u want to shine like a sun, first burn like a sun"',
        about: '""',

        linkedIn: "https://www.linkedin.com/in/karthik-gowda-k-a-161b4b217/",
        emailId: "mailto:karthikgowdaka001@gmail.com",
      },



      {
        id: "2022-13",
        name: "Keertith V",
        image: Keertith,
        role: "Steering System Engineer",

        intro: '""',
        about: "",

        linkedIn: "https://www.linkedin.com/in/keertith-veeresh-2371421a9/",
        emailId: "mailto:keertithveeresh01@gmail.com",
      },


      {
        id: "2022-14",
        name: "Kishore G",
        image: Kishore,
        role: "Suspension Engineer",

        intro: '"Hardworking, dedicated and likes fast and beautiful cars"',
        about: '""',

        linkedIn: "https://www.linkedin.com/in/kishoreg7435/",
        emailId: "mailto:kishoreg1947@gmail.com",
      },

      {
        id: "2022-15",
        name: "Chiranjeevi Raghavindra",
        image: Chiranjeevi,
        role: "Business Plan Lead",

        intro: '"Do them dirty in front of they squad."',
        about: "",

        linkedIn:
          "https://www.linkedin.com/in/chiranjeevi-raghavindra-7a3073213/",
        emailId: "mailto:chiranjeeviraghavindra@gmail.com",
      },   

      {
        id: "2022-16",
        name: "Atharva Bilgi",
        image: Atharva,
        role: "Powertrain Junior",

        intro: '"Dream. Create. Inspire."',
        about: "",

        linkedIn:
          "https://in.linkedin.com/in/atharva-bilgi-a61879237",
        emailId: "mailto:atharvapravin28@gmail.com",
      },  
      
      {
        id: "2022-17",
        name: "Anuj Deepak",
        image: Anuj,
        role: "Structures Junior",

        intro: '"Dream. Create. Inspire."',
        about: "",

        linkedIn:
          "https://in.linkedin.com/in/anuj-deepak-81437b218",
        emailId: "mailto:anujdeepak7@gmail.com",
      },  
      
      {
        id: "2022-18",
        name: "Pranjal p",
        image: Pranjal,
        role: "Media Team",

        intro: '"Dream. Create. Inspire."',
        about: "",

        linkedIn:
          "https://www.linkedin.com/in/pranjal-p-77417a236",
        emailId: "mailto:pranjalwalker@gmail.com",
      },
        

    ],
  },
  {
    id: "2021",
    teamMembers: [
      {
        id: "2021-0",
        name: "Niranjan Bharadwaj",
        image: Niranjan,
        role: "Team Captain",

        intro: "",
        about: "",

        linkedIn: "https://www.linkedin.com/in/niranjan-bharadwaj-2339021a5/",
        emailId: "mailto:niranjan.bharadwaj11@gmail.com",
      },

      {
        id: "2021-1",
        name: "Sanjeev Kumar S",
        image: Sanjeev,
        role: "Team manager",

        intro: '"The Realist!"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/sanjeev-kumar-s1899/",
        emailId: "mailto:sanjuraju43@gmail.com",
      },

      {
        id: "2021-2",
        name: "Vaibhav Harinarayan",
        image: Vaibhav,
        role: "Vice Captain",

        intro: '"Think - Innovate - Design - Fabricate"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/vaibhav-harinarayan-232455135",
        emailId: "mailto:vaibhavharinarayan@gmail.com",
      },

      {
        id: "2021-3",
        name: "Satvik S",
        image: Satvik,
        role: "Vice Captain",
        intro: '"Desire is the engine of creation."',
        about:
          "I am a creative and motivated problem solver. I learn quickly, love to take intiative and have experience in engineering and design solutions as well as dealing with a realistic and practical approach to issues.",

        linkedIn: "https://www.linkedin.com/in/satvik-s-525364194/",
        emailId: "mailto:satvikcrvk718@gmail.com",
      },

      {
        id: "2021-4",
        name: "Soumyadeep .",
        image: Deep,
        role: "Powertrain Engineer",

        intro: '"Hoot!"',
        about: "",

        linkedIn: "https://in.linkedin.com/in/-deep",
        emailId: "mailto:soumyadeep.br@gmail.com",
      },

      {
        id: "2021-5",
        name: "Abhinav Joshi",
        image: Abhinav,
        role: "Braking Systems Lead",

        intro: '"Always enthusiastic to work with machines"',
        about: "",

        linkedIn: "",
        emailId: "mailto:abhinavjoshi456@gmail.com",
      },

      {
        id: "2021-6",
        name: "Karthik Raghunath",
        image: Karthik,
        role: "Suspension Systems Lead",

        intro: '"Crank up dreams , mesh with the reality."',
        about: "",

        linkedIn: "https://www.linkedin.com/in/karthik-raghunath",
        emailId: "mailto:karthik.raghunath980@gmail.com",
      },

      {
        id: "2021-7",
        name: "Amar S Wali",
        image: Amar,
        role: "Electrical Systems Lead",
        intro: '"Timepass"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/amar-wali-3210761a0/",
        emailId: "mailto:amarwali0987654321@gmail.com",
      },

      {
        id: "2021-8",
        name: "Rijul MK",
        image: Rijul,
        role: "Drivetrain Lead",

        intro: '"A wanderlust petrolhead."',
        about: "",

        linkedIn: "https://www.linkedin.com/in/rijul-m-k-55998420a",
        emailId: "mailto:rijulmk2001@gmail.com",
      },

      {
        id: "2021-9",
        name: "Vachan M",
        image: Vachan,
        role: "Suspension System Engineer",

        intro: '"Just a guy trying new things | Fascinated by Machines"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/vachan-maruthi-99a098174/",
        emailId: "emailto:agathvegabonds@gmail.com",
      },

      {
        id: "2021-10",
        name: "Keertith V",
        image: Keertith,
        role: "Steering System Engineer",

        intro: '""',
        about: "",

        linkedIn: "https://www.linkedin.com/in/keertith-veeresh-2371421a9/",
        emailId: "mailto:keertithveeresh01@gmail.com",
      },

      {
        id: "2021-11",
        name: "Sai Krishna P Sreenivas",
        image: Saikrishna,
        role: "Aerodynamics Lead",

        intro: '"In love with Long drives and rides"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/skp313/",
        emailId: "mailto:saikrishna.313@gmail.com",
      },

      {
        id: "2021-12",
        name: "Shreyas SR",
        image: Shreyas,
        role: "Business Plan Strategist",

        intro:
          '"Learning something new everyday to keep this adventure going on"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/shreyas-sr-281a0820a/",
        emailId: "mailto:shreyassr162001@gmail.com",
      },

      {
        id: "2021-13",
        name: "Sandesh T",
        image: Sandesh,
        role: "Aerodynamics Engineer",

        intro: '"😺 and 🚙 lover"',
        about: "",

        linkedIn: "https://www.linkedin.com/in/sandesh-t-v-391277143/",
        emailId: "mailto:1ms19me155@gmail.com",
      },

      {
        id: "2021-14",
        name: "Abdul Mateen",
        image: Abdul,
        role: "Aerodynamics Engineer",

        intro: "新兵たちを地獄に導けAn introvert weeb",
        about: "",

        linkedIn: "https://www.linkedin.com/in/abdul-mateen-01/",
        emailId: "mailto:abdulmateen7501@gmail.com",
      },

      {
        id: "2021-15",
        name: "Tejas B N Shetty",
        image: Tejas,
        role: "Junior Aerodynamics Engineer",

        intro: '"Passion and motivation for perfection drives me."',
        about: "",

        linkedIn: "https://www.linkedin.com/in/tejas-bn-shetty-55b397213/",
        emailId: "mailto:tejasbnshetty@gmail.com",
      },

      {
        id: "2021-16",
        name: "Chiranjeevi Raghavindra",
        image: Chiranjeevi,
        role: "Junior Drivetrain Engineer",

        intro: '"Do them dirty in front of they squad."',
        about: "",

        linkedIn:
          "https://www.linkedin.com/in/chiranjeevi-raghavindra-7a3073213/",
        emailId: "mailto:chiranjeeviraghavindra@gmail.com",
      },

      {
        id: "2021-17",
        name: "Hari Abhijit V G",
        image: HariAbhijit,
        role: "Junior Steering System Engineer",

        intro: '"Love to explore new things and keep learning"',
        about: '""',

        linkedIn: "",
        emailId: "mailto:hariabhijit2003@gmail.com",
      },

      {
        id: "2021-18",
        name: "Kishore G",
        image: Kishore,
        role: "Junior Suspension System Engineer",

        intro: '"Hardworking, dedicated and likes fast and beautiful cars"',
        about: '""',

        linkedIn: "https://www.linkedin.com/in/kishoreg7435/",
        emailId: "mailto:kishoreg1947@gmail.com",
      },

      {
        id: "2021-19",
        name: "K M Nagarjun",
        image: Nagarjun,
        role: "Junior Powertrain Engineer",

        intro: '"Just go with the flow"',
        about: '""',

        linkedIn: "https://www.linkedin.com/in/km-nagarjun-16b161213/",
        emailId: "mailto:km.nagarjun06@gmail.com",
      },

      {
        id: "2021-20",
        name: "Karthik Rakesh",
        image: KarthikRakesh,
        role: "Junior Aerodynamics Engineer",

        intro: '"Sometimes you gotta run before you can walk"',
        about: '""',

        linkedIn: "https://www.linkedin.com/in/karthik-rakesh-b46851222/",
        emailId: "mailto:karthikrakesh15@gmail.com",
      },

      {
        id: "2021-21",
        name: "Karthik Gowda K A",
        image: KarthikGowda,
        role: "Junior Drivetrain Engineer",

        intro: '"If u want to shine like a sun, first burn like a sun"',
        about: '""',

        linkedIn: "https://www.linkedin.com/in/karthik-gowda-k-a-161b4b217/",
        emailId: "mailto:karthikgowdaka001@gmail.com",
      },

      {
        id: "2021-22",
        name: "Sumukh Varma Y",
        image: Sumukh,
        role: "Junior Powertrain Engineer",

        intro: '"On the edge"',
        about: '""',

        linkedIn: "",
        emailId: "mailto:sumukh.varmay@gmail.com",
      },

      {
        id: "2021-23",
        name: "Neelay Unde",
        image: Neelay,
        role: "Junior Steering Engineer",

        intro:
          '"A racing car is an animal with a thousand adjustments. -Mario Andretti"',
        about: '""',

        linkedIn: "",
        emailId: "mailto:neelayunde@gmail.com",
      },

      {
        id: "2021-24",
        name: "Sanskar Gondkar",
        image: Sanskar,
        role: "Junior Aerodynamics Engineer",

        intro: '"Games, music and cars; me , a summary"',
        about: '""',

        linkedIn: "",
        emailId: "mailto:sanskargondkar2@gmail.com",
      },

      {
        id: "2021-25",
        name: "Abhishek Baskar",
        image: Abhishek,
        role: "Junior Business Plan Strategist",

        intro: '"Happy"',
        about: '""',

        linkedIn: "",
        emailId: "mailto:carmeloabhi246@gmail.com",
      },
    ],
  },

  {
    id: "2020",
    teamMembers: [
      // {
      //     id: 'A1',
      //     name: 'deep deep',
      //     image: '',
      //     role: 'powertrain engineer',
      //     linkeIn: '',
      //     emailId: '',
      // },
      // {
      //     id: 'A2',
      //     name: 'Satvik S',
      //     image: '',
      //     role: 'powertrain engineer',
      //     linkeIn: '',
      //     emailId: '',
      // },
      // {
      //     id: 'A3',
      //     name: 'Satvik S',
      //     image: '',
      //     role: 'powertrain engineer',
      //     linkeIn: '',
      //     emailId: '',
      // },
      // {
      //     id: 'A4',
      //     name: 'Satvik S',
      //     image: '',
      //     role: 'powertrain engineer',
      //     linkeIn: '',
      //     emailId: '',
      // },
    ],
  },
];
